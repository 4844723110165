import * as React from 'react'

import InquiryForm from '../components/inquiryForm'
import SidebarLayout from '../components/sidebarLayout.js'

const Request
    = ({ pageContext }) => {


        return (
            <SidebarLayout title="Schulung anfragen"
                pageContext={pageContext}
                main={(

                    <div >
                        <h1>Anfrage</h1>
                        <p>
                            Wenn Sie Interesse an einer Schulung haben, sollten Sie sich von uns ein Angebot erstellen lassen. Dazu </p>

                        <InquiryForm  />
                    </div>)}
                sidebar={(
                    <div></div>

                )}
            />
        )
    }


export default Request